<template>
  <div>
    <div class="text-end">
      <button class="contract-download-btn" @click="exportToPDF">
        <i
          v-if="!this.isDownloading"
          class="fas fa-download contract-download-icon"
        />
        <i v-else class="fas fa-spinner fa-spin contract-download-icon" /> Download PDF
      </button>
    </div>

    <div class="table-responsive" v-if="contractSheetList.length > 0">
      <br />
      <table ref="document" class="table">
        <thead>
          <tr>
            <th>Plan Name</th>
            <th>Tier</th>
            <th
              v-for="(level, index) in renderLevelForTableField()"
              :key="index"
            >
              {{ level }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="contractSheet in contractSheetList"
            :key="
              contractSheet.contract_item_display_name ||
              contractSheet.plan_display_name
            "
          >
            <td>
              {{
                contractSheet.contract_item_display_name ||
                contractSheet.plan_display_name
              }}
            </td>
            <td>{{ contractSheet.display_name || "All" }}</td>
            <td v-for="level in agentLevel" :key="level">
              <template v-if="contractSheet.configuration[`L${level}`]">
                {{ contractSheet.configuration[`L${level}`] }}
              </template>
              <template v-else>-</template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-center" v-else>No data found</div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  name: "CommissionShowTable",
  data() {
    return {
      highestLevel: 0,
      isDownloading: false,
    };
  },
  props: {
    contractSheetList: {
      type: Array,
      required: true,
    },
    agentLevel: {
      Number,
    },
    fileName: {
      String,
    },
  },
  mounted() {
    this.renderLevelForTableField();
  },
  methods: {
    renderLevelForTableField() {
      let levelValue = [];
      for (let i = 1; i <= this.agentLevel; i++) {
        levelValue.push(`Level ${i}`);
      }
      return levelValue;
    },
    exportToPDF() {
      let loader = this.$loading.show()
      this.isDownloading = true;
      html2pdf()
        .from(this.$refs.document)
        .set({
          margin: 0.2,
          filename: `${this.fileName}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { dpi: 192, letterRendering: true },
          jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
        })
        .save()
        .then(() => {
          this.isDownloading = false;
          loader.hide()
        });
    },
  },
};
</script>

<style scoped>
.contract-download-icon {
  font-size: 14px;
  margin-right: 10px;
}
.contract-download-btn {
  background: transparent
    linear-gradient(180deg, #00ccff 0%, #2597b8 49%, #0567bc 100%) 0% 0%
    no-repeat padding-box;
  color: #fff;
  box-shadow: 0px 8px 20px -13px rgb(0 0 0 / 69%);
  border-radius: 3px;
  padding: 8px 20px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
}
table {
  border: 1px solid #dee2e6;
}
table thead tr th {
  border: 1px solid #dee2e6;
}
table tbody tr td {
  border: 1px solid #dee2e6;
}
.table-border {
  border-bottom: 1px solid white;
}
</style>
