<template>
  <div class="page-wrapper commissions-wrap">
    <div class="container-fluid">
      <div>
        <breadcrumb :items="breadcrumbItems" />
      </div>
      <div class="config-wrap config-tab-wrap">
        <div class="page-title mb-3">
          <h1>Commission Schedule</h1>
        </div>
        <div v-if="isContractInfoList" class="text-center">No data found</div>

        <b-card no-body>
          <b-tabs>
            <b-tab
              v-for="(contract, index) in contractInfoList"
              :key="contract.contract_id"
              :title="getContractTitle(contract)"
              @click="handleTabClick(contract.contract_id, index)"
              :active="index === activeTab"
            >
              <b-card-text>
                <CommissionShowTable
                  :contractSheetList="contractList"
                  :agentLevel="agentLevel"
                  :fileName="fileName"
                />
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CommissionShowTable from "./CommissionShowTable.vue";

export default {
  name: "CommissionConfig",
  components: {
    CommissionShowTable,
  },
  created() {
    this.getContractInfoList();
  },
  data: () => ({
    commScroll: {
      vuescroll: {
        mode: "native",
        wheelScrollDuration: "700",
      },
      scrollPanel: {
        maxHeight: 500,
        speed: 2000,
      },
      bar: {
        background: "#ddd",
      },
    },
    showCommissionTableConfig: false,
    contractSheetList: [],
    contractPremierList: [],
    contractList: [],
    contractInfoList: [],
    isContractInfoList: false,
    tabTittle: "",
    type: "",
    agentLevel: null,
    breadcrumbItems: [{ title: "Commission Schedule", active: true }],
    activeTab: 0,
    fileName: ''
  }),
  computed: {
    repInfo() {
      return this.$store.getters.rep;
    },
  },
  methods: {
    getContractInfoList() {
      let loader = this.$loading.show();
      let url = `/v2/get-agent-contract-info`;

      axios
        .get(url)
        .then((res) => {
          this.contractInfoList = res.data.data;
          if (this.contractInfoList.length == 0) {
            this.isContractInfoList = true;
          }
          if (this.contractInfoList.length > 0) {
            this.handleTabClick(this.contractInfoList[0].contract_id, 0);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getContractTitle(contract) {
      if (contract.contract_display_name === "Non-Category Contract") {
        return this.capitalizeFirstLetter(contract.contract_type);
      } else {
        return contract.contract_display_name;
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    handleTabClick(contractId, index) {
      this.activeTab = index;
      this.getCommissionList(contractId);
      this.updateFileName(this.contractInfoList[index]);
    },
    updateFileName(contract) {
      if (contract.contract_display_name === "Non-Category Contract") {
        this.fileName = this.capitalizeFirstLetter(contract.contract_type);
      } else {
        this.fileName = contract.contract_display_name.split(" ").join('');
      }
    },
    getCommissionList(contractId) {
      const encodedContractId = btoa(contractId);
      let loader = this.$loading.show();
      let url = `${this.$endpoint.PURENROLL_BASE_URL}/api/v2/masterSheetForCategoryLevel/${encodedContractId}`;

      axios
        .get(url)
        .then((res) => {
          this.contractList = [];
          this.agentLevel = null;
          this.contractList = this.mapContractSheetList(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loader.hide();
        });
    },
    mapContractSheetList(params) {
      let contractSheetList = [];
      this.agentLevel = params.max_level;
      params.data.forEach((data) => {
        if (data.is_tier_based == 0) {
          contractSheetList.push(data);
        } else if (data.is_tier_based === 1) {
          if (data.configuration.length !== 0) {
            let planName = data.contract_item_name;
            let planDisplayName = data.contract_item_display_name;
            data.configuration.plan_tier.forEach((data, index) => {
              let obj = {};
              let configuartion = {};
              if (index == 0) {
                obj["plan_name"] = planName;
                obj["plan_display_name"] = planDisplayName;
              }
              configuartion = data;
              obj["configuartion"] = configuartion;
              obj = {
                ...obj,
                ...obj.configuartion,
              };
              delete obj.configuartion;
              contractSheetList.push(obj);
            });
          } else {
            console.log("empty list");
            /*    let obj = {}
                let configuartion = {}
                obj['plan_id'] = data.plan_id
                obj['plan_name'] = data.plan_name
                configuartion['tier'] = 'N/A'
                configuartion['value'] = this.addTierLevel(this.agentLevel);
                obj['configuartion'] = configuartion
                contractSheetList.push(obj)*/
          }
        }
      });
      return contractSheetList;
    },
    /* addTierLevel(levelValue) {
       let value = {}
       for (let i = 1; i <= levelValue; i++) {
         value['l' + i] = 'N/A';
       }
       return value;
     }*/
  },
  /* addTierLevel(levelValue) {
      let value = {}
      for (let i = 1; i <= levelValue; i++) {
        value['l' + i] = 'N/A';
      }
      return value;
    }*/
};
</script>
<style>
.fileListing-card table tr td,
.fileListing-card table tr th {
  border: 1px solid #d7e1ea;
  padding: 12px;
  vertical-align: top;
}

.config-wrap .card {
  border: none;
}

.config-wrap .card .nav-tabs {
  border-bottom: none;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.config-wrap .card .nav-tabs .nav-link.active {
  background: transparent
    linear-gradient(180deg, #00ccff 0%, #2597b8 49%, #0567bc 100%) 0% 0%
    no-repeat padding-box;
  color: #fff;
  box-shadow: 0px 8px 20px -13px rgb(0 0 0 / 69%);
}
.config-tab-wrap .card .tabs ul li {
  margin-right: 10px !important;
  margin-bottom: 0.8rem;
}
.config-wrap .card .nav-tabs .nav-link {
  color: #03c3ec;
  border: 1px solid #03c3ec;
  display: inline-block;
  padding: 7px 30px;
  margin-right: 10px;
  text-align: center;
  border-radius: 60px;
  font-size: 14px;
  background: transparent;

  width: 100%;
}

.config-wrap table {
  border: 1px solid #dee2e6;
  width: 100%;
  height: 150px;
  overflow: scroll;
}

.config-wrap table thead tr th {
  border-bottom: 0;
  background: #fafbfd;
  border: 1px solid #dee2e6;
  color: #254050;
  font-weight: bold;
  font-size: 13px;
}

.config-wrap table tbody tr td {
  color: #254050;
  font-size: 13px;
  border: 1px solid #dee2e6;
}

/* .table-responsive {
  overflow: scroll;
} */
@media (max-width: 545px) {
  .config-wrap .card .nav-tabs{
    display: block;
  }
}

@media (max-width: 396px) {
  .config-wrap .card .nav-tabs .nav-link {
    margin-bottom: 0.5rem;
    width: 100%;
  }
}
</style>