var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper commissions-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  })], 1), _c('div', {
    staticClass: "config-wrap config-tab-wrap"
  }, [_vm._m(0), _vm.isContractInfoList ? _c('div', {
    staticClass: "text-center"
  }, [_vm._v("No data found")]) : _vm._e(), _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-tabs', _vm._l(_vm.contractInfoList, function (contract, index) {
    return _c('b-tab', {
      key: contract.contract_id,
      attrs: {
        "title": _vm.getContractTitle(contract),
        "active": index === _vm.activeTab
      },
      on: {
        "click": function click($event) {
          return _vm.handleTabClick(contract.contract_id, index);
        }
      }
    }, [_c('b-card-text', [_c('CommissionShowTable', {
      attrs: {
        "contractSheetList": _vm.contractList,
        "agentLevel": _vm.agentLevel,
        "fileName": _vm.fileName
      }
    })], 1)], 1);
  }), 1)], 1)], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title mb-3"
  }, [_c('h1', [_vm._v("Commission Schedule")])]);
}]

export { render, staticRenderFns }